<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold black--text">
          อัพโหลด ข้อมูล CRM
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="เดือน"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="monthRules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" locale="th" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(date);
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-file-input
            v-model="file"
            ref="file"
            :rules="rules"
            small-chips
            color="secondary"
            accept=".xls, .xlsx"
            prepend-icon="mdi-microsoft-excel"
            required
          ></v-file-input>
        </v-form>
        <br />
        <v-progress-linear
          :active="uploading"
          v-model="uploadPercentage"
          height="25"
        >
          <strong> {{ uploadPercentage }}% </strong>
        </v-progress-linear>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          class="body-2 font-weight-bold"
          outlined
          @click="upload()"
          >Upload</v-btn
        >
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click="cancel()"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadCRMDataDialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      file: null,
      uploadCRMDataResponse: null,
      uploading: false,
      modal: false,
      uploadPercentage: 0,
      date: new Date().toISOString().substr(0, 7),
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      rules: [
        (value) => !!value || "File is required",
        (value) =>
          !value ||
          value.size < 100000000 ||
          "File size should be less than 100 MB!",
      ],
      monthRules: [
        (value) => !!value || "Month is required",
      ],
    };
  },
  methods: {
    open: function () {
      this.dialog = true;
      // console.log(albumIndex);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    upload() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("upload", this.file, this.file.name);
        formData.append('month', this.date);
        // console.log(formData);
        axios
          .post("/crm-data/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploading = true;
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            this.uploadCRMDataResponse = response.data;
            if (this.uploadCRMDataResponse.status === "success") {
              this.uploading = false;
              this.loading = false;
              this.$refs.form.reset();
              this.resolve(true);
              this.dialog = false;
            }
          })
          .catch(function () {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>